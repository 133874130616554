import React from 'react';
import { Container } from '@mui/material';
import { ReviewProps } from './ReviewRow';
import AddReview from './AddReview';
import ReviewsList from './ReviewsList';
import {Review} from "../api/generated";
import {NotFoundError} from "../api/NotFound";
import ReviewService from "../api/services/ReviewService";
import {useQuery} from "@tanstack/react-query";

const ReviewsContainer: React.FC = () => {
    const {data, status, isLoading, error, isError} =
        useQuery<Review[], NotFoundError>({queryKey: ['reviews'], queryFn: () => ReviewService.getAllReviews()})


  return (
    <Container maxWidth="sm">
      <AddReview />
      {/*<ReviewsList reviews={data} pageSize={5} />*/}
    </Container>
  )
}

export default ReviewsContainer;