import React, {useEffect, useState} from 'react';
import { Button, TextareaAutosize, TextField, Typography, Grid, Rating, Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import ReviewMutation from "../api/services/ReviewMutation";
import {MuiFileInput} from "mui-file-input";
import {MediaItemReq} from "../api/generated";
import {readFileAsBase64} from "../utils/utils";
import {useToast} from "./toast/useToast";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";



const AddReview: React.FC = () => {
    const { addReview } = ReviewMutation();
    const [name, setName] = useState('');
    const [orderNumber, setOrderNumber] = useState<string>('');
    const [rating, setRating] = useState<number | null>(null);
    const [text, setText] = useState('');
    const [file, setFile] = useState<File>();

    const navigate = useNavigate();
    const location = useLocation();
    const [urlParams] = useSearchParams();
    const orderParam = urlParams.get('order') || "";

    useEffect(() => {
        setOrderNumber(orderParam);
    }, [orderParam]);

    const handleClearParams = () => {
        // Získat aktuální cestu bez parametrů
        const pathWithoutParams = location.pathname;

        // Navigovat na novou URL bez parametrů
        navigate(pathWithoutParams, { replace: true });
    };

    const [submitAttempted, setSubmitAttempted] = useState(false);

    const { showToast } = useToast();

    const handleChange = (newFile: any) => {
        console.log("FILE", newFile);

        if (newFile) {
            const media: MediaItemReq = {
                data: newFile.name,
            };
        }
        setFile(newFile);
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmitAttempted(true);

        if (rating === null) {
            return;
        }

        const mediaItems: MediaItemReq[] = [];

        if (file) {
            const base64Data = await readFileAsBase64(file);
            const mediaItem: MediaItemReq = {
                fileName: file.name,
                type: file.type,
                data: base64Data,
            };

            mediaItems.push(mediaItem);
        }

        addReview.mutate({ name, orderNumber, rating: rating ? rating : 0, reviewText: text, photos: mediaItems });

        setName('');
        setRating(null);
        handleClearParams();
        setText('');
        setFile(undefined);
        setSubmitAttempted(false);
    };
    return (
        <Box pt={5} pb={5}>
            <form onSubmit={onSubmit}>
                <Typography variant="h6" gutterBottom>
                    Podělte se s námi o vaši zkušenost s <a href="https://www.tagli.cz" target="_blank" rel="noopener noreferrer">Tagli.cz</a>
                </Typography>
                <Typography variant={"body1"}>
                    Vaše zpětná vazba je pro nás klíčová. Pomáhá nám neustále vylepšovat naše služby i produkty a přizpůsobovat je přesně vašim potřebám, abychom vám mohli přinášet to nejlepší.                </Typography>
                <Grid container spacing={2} pt={10} pb={5}>
                    <Grid item sm={7} xs={12}>
                        <TextField
                            fullWidth
                            label="Jméno"
                            value={name}
                            onChange={event => setName(event.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <TextField
                            fullWidth
                            label="Číslo objednávky"
                            value={orderNumber}
                            onChange={event => setOrderNumber(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="legend">Hodnocení</Typography>
                        <Box component="fieldset" borderColor="transparent">
                            <Rating
                                name="simple-controlled"
                                value={rating}
                                onChange={(event, newValue) => {
                                    setRating(newValue);
                                }}
                            />

                        </Box>
                        {submitAttempted && rating === null && (
                            <Typography variant="caption" color="error">
                                Vyplňte prosím hodnocení.
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            label="Vaše recenze"
                            value={text}
                            onChange={event => setText(event.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <MuiFileInput
                            value={file}
                            onChange={handleChange}
                            size="small"
                            variant="outlined"
                            inputProps={{ accept: ['.jpg', '.jpeg'] }}
                            label={"Fotografie"}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Button type="submit" variant="contained" color="primary">
                            Odeslat
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default AddReview;
