import * as React from 'react';
import { Box, CssBaseline } from '@mui/material';
import Footer from "./Footer";

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh', // Zajištění minimální výšky stránky
            }}
        >
            <CssBaseline />
            {/* Hlavní obsah */}
            <Box
                sx={{
                    flex: '1', // Obsah se přizpůsobí prostoru
                }}
            >
                {children}
            </Box>
            {/* Footer */}
            <Footer />
        </Box>
    );
};

export default Layout;
